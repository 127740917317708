dmx.Component('autocomplete', {

    extends: 'input',

    initialData: {
        selectedItem: null
    },

    attributes: {
        data: {
            type: Array,
            default: []
        },

        matchcase: {
            type: Boolean,
            default: false
        },

        matchstart: {
            type: Boolean,
            default: false
        },

        matchaccent: {
            type: Boolean,
            default: false
        },

        optiontext: {
            type: String,
            default: '$value'
        },

        optionvalue: {
            type: String,
            default: '$value'
        },

        noresultslabel: {
            type: String,
            default: 'No Results...'
        }
    },

    render: function(node) {
        dmx.BaseComponent.prototype.render.call(this, node);

        this.dataItems = [];

        if (!this.props.value) {
            this.props.value = this.$node.value;
        } else {
            this.updateValue = true;
        }

        this.$node.disabled = this.props.disabled;

        this.input = this.$node.cloneNode();
        this.input.removeAttribute('id');
        this.input.removeAttribute('name');
        this.input.setAttribute('autocomplete', 'off');
        this.input.setAttribute('form', 'dummy'); // form should ignore this input
        this.input.addEventListener('blur', this.onBlur.bind(this));
        this.input.addEventListener('input', this.onInput.bind(this));
        this.input.addEventListener('keydown', this.onKeydown.bind(this));

        this.list = document.createElement('div');
        this.list.setAttribute('class', 'dmx-autocomplete-items');

        this.$node.style.display = 'none';
        this.$node.addEventListener('change', this.updateData.bind(this));
        this.$node.addEventListener('invalid', this.updateData.bind(this));

        this.update({});
    },

    mounted: function() {
        this.$node.parentNode.insertBefore(this.input, this.$node);

        if (this.$node.form) {
            this.$node.form.addEventListener('reset', this.onReset.bind(this));
        }
    },

    update: function(props) {
        if (JSON.stringify(this.props.data) != JSON.stringify(props.data) || this.props.optiontext != props.optiontext || this.props.optionvalue != props.optionvalue) {
            this.dataItems = dmx.repeatItems(this.props.data).map(function(item, index) {
                return {
                    index: index,
                    value: dmx.parse(this.props.optionvalue, dmx.DataScope(item, this)),
                    label: dmx.parse(this.props.optiontext, dmx.DataScope(item, this)),
                    item: item
                }
            }, this);

            this.updateValue = true;
        }

        if (this.props.value != props.value) {
            this.updateValue = true;
        }

        if (this.props.disabled != props.disabled) {
            this.$node.disabled = this.props.disabled;
            this.input.disabled = this.props.disabled;
        }

        this.updateData();
    },

    updated: function() {
        if (this.updateValue) {
            this.updateValue = false;
            this.setValue(this.props.value, true);
            this.updateData();
        }
    },

    updateData: function(event) {
        dmx.Component('form-element').prototype.updateData.call(this, event);
        var selectedItem = this.dataItems.find(function(item) {
            return item.value == this.$node.value;
        }, this);
        this.set('selectedItem', selectedItem ? selectedItem.item : null);
    },

    setValue: function(value, isDefault) {
        var selectedItem = this.dataItems.find(function(item) {
            return item.value == value;
        }, this);

        this.$node.value = selectedItem ? selectedItem.value : '';
        this.input.value = selectedItem ? selectedItem.label : '';

        if (isDefault) {
            this.$node.defaultValue = selectedItem ? selectedItem.value : '';
            this.input.defaultValue = selectedItem ? selectedItem.label : '';
        }
    },

    focus: function() {
        this.input.focus();
    },

    disable: function(disable) {
        this.$node.disabled = (disable === true);
        this.input.disabled = (disable === true);
        this.updateData();
    },

    onReset: function(event) {
        this.$node.value = this.$node.defaultValue;
        this.input.value = this.input.defaultValue;
    },

    onBlur: function(event) {
        var valid = false;

        if (this.dataItems) {
            for (var i = 0; i < this.dataItems.length; i++) {
                if (this.dataItems[i].label == this.input.value) {
                    this.$node.value = this.dataItems[i].value;
                    valid = true;
                }
            }
        }

        if (!valid) {
            this.input.value = '';
            this.$node.value = '';
        }

        var evt = document.createEvent("HTMLEvents");
        evt.initEvent("change", false, true);
        this.$node.dispatchEvent(evt);

        this.closeList();
    },

    onInput: function(event) {
        var $node = this.$node;
        var input = this.input;
        var value = input.value;

        this.closeList();

        if (!value) return;
        if (!this.dataItems.length) return;

        this.currentFocus = -1;

        this.list.innerHTML = '';

        this.dataItems.forEach(function(item) {
            if (this.matches(item.label, value)) {
                var div = document.createElement('div');
                div.setAttribute('class', 'dmx-autocomplete-item');
                div.innerHTML = this.getItemHtml(item.label, value);
                div.item = item;

                div.addEventListener('mousedown', function(event) {
                    event.preventDefault();
                    event.stopPropagation();
                    $node.value = item.value;
                    input.value = item.label;
                    this.closeList();
                    var evt = document.createEvent("HTMLEvents");
                    evt.initEvent("change", false, true);
                    $node.dispatchEvent(evt);
                }.bind(this));

                this.list.appendChild(div);
            }
        }, this);

        if (!this.list.children.length) {
            this.list.innerHTML = '<div class="dmx-autocomplete-item">' + this.props.noresultslabel + '</div>';
        }

        this.showList();
    },

    onKeydown: function(event) {
        if (event.keyCode == 40) {
            event.preventDefault();
            this.currentFocus++;
            this.updateActive();
            this.showList();
        } else if (event.keyCode == 38) {
            event.preventDefault();
            this.currentFocus--;
            this.updateActive();
            this.showList();
        } else if (event.keyCode == 27) {
            event.preventDefault();
            this.currentFocus = -1;
            this.closeList();
        } else if (event.keyCode == 13) {
            // prevent the form from being submitted
            event.preventDefault();

            if (this.currentFocus > -1) {
                var divs = this.list.getElementsByTagName('div');
                var evt = document.createEvent("HTMLEvents");
                evt.initEvent("mousedown", false, true);
                if (divs) divs[this.currentFocus].dispatchEvent(evt);
            }
        }
    },

    matches: function(item, value) {
        if (item == null) return false;

        if (!this.props.matchaccent) {
            item = this.normalize(item);
            value = this.normalize(value);
        }

        if (!this.props.matchcase) {
            item = item.toLowerCase();
            value = value.toLowerCase();
        }

        if (this.props.matchstart) {
            item = item.substring(0, value.length);
        }

        return item.indexOf(value) != -1;
    },

    getItemHtml: function(item, value) {
        var html = this.htmlEncode(item);
        var matcher = new RegExp((this.props.matchstart ? '^' : '') + dmx.escapeRegExp(this.htmlEncode(value)), this.props.matchcase ? 'g' : 'gi');

        html = html.replace(matcher, function(m) {
            return '<b>' + m + '</b>';
        });

        return html;
    },

    htmlEncode: function(html) {
        html = html.replace(/&/g, '&amp;');
        html = html.replace(/"/g, '&quot;');
        html = html.replace(/</g, '&lt;');
        html = html.replace(/>/g, '&gt;');
        return html;
    },

    normalize: function(str) {
        if (str.normalize) {
            return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
        }

        var TAB_00C0 = 'AAAAAAACEEEEIIIIDNOOOOO*OUUUUYIsaaaaaaaceeeeiiii?nooooo/ouuuuy?yAaAaAaCcCcCcCcDdDdEeEeEeEeEeGgGgGgGgHhHhIiIiIiIiIiJjJjKkkLlLlLlLlLlNnNnNnnNnOoOoOoOoRrRrRrSsSsSsSsTtTtTtUuUuUuUuUuUuWwYyYZzZzZzF';
        var result = str.split('');

        for (var i = 0; i < result.length; i++) {
            var c = str.charCodeAt(i);
            if (c >= 0x00c0 && c <= 0x017f) {
                result[i] = String.fromCharCode(TAB_00C0.charCodeAt(c - 0x00c0));
            } else if (c > 127) {
                result[i] = '?';
            }
        }

        return result.join('');
    },

    updateActive: function() {
        var divs = this.list.getElementsByTagName('div');

        if (!divs) return;
        if (this.currentFocus >= divs.length) this.currentFocus = 0;
        if (this.currentFocus < 0) this.currentFocus = divs.length - 1;

        for (var i = 0; i < divs.length; i++) {
            divs[i].classList.remove('dmx-autocomplete-active');
        }

        divs[this.currentFocus].classList.add('dmx-autocomplete-active');
    },

    showList: function() {
        if (!this.input.value) return;
        if (this.$node.nextSibling) {
            this.$node.parentNode.insertBefore(this.list, this.$node.nextSibling);
        } else {
            this.$node.parentNode.appendChild(this.list);
        }
    },

    closeList: function() {
        if (this.list.parentNode) {
            this.list.parentNode.removeChild(this.list);
        }
    }

});
